<template>
  <div class="messageNoticeBox">
    <div class='container'>
      <h5>消息通知</h5>
      <div class="list">
        <div class="notice-item" v-for="(item,index) in noticeInfo.list" :key="item.id" :style="item.show?'background: #ECF2FB;':''">
          <div class="notice-header" @click="item.show=!item.show;getNoticeInfo(item,index)">
            <h6>{{item.title}} <span  v-if="item.state == false" class="badge"></span>
            </h6>
            <div class="date">
              <span>{{item.create_time}}</span>
              <img src="@assets/img/profile/icon-arrow_open.png" :class="item.show?'notice-active':''"/>
            </div>
          </div>
          <el-collapse-transition>
            <div class="notice-content" v-show="item.show" v-html="item.content">
            </div>
          </el-collapse-transition>
        </div>
        <el-pagination
            v-if="noticeInfo.count>10"
            :page-size="10"
            background
            layout="prev, pager, next"
            @current-change="handleCurrentChange"
            :total="noticeInfo.count">
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import {noticeData, noticeInfo} from "@assets/api/notice/notice";
import {getUserInfo} from "@assets/api/user/user";

export default {
  name:'',
  components: {},

  data () {
    return {
        list:[
            {id:1,show:false},
            {id:2,show:false},
        ],
      pageIndex:1,//分页
      noticeInfo:{}
    };
  },

  computed: {},

  created(){
    this.getNoticeData()
  },

  methods: {

    getUser(){
      getUserInfo().then(res=>{
        if(res.code == 1){
          let userInfo = res.data
          // let time = userInfo.vip_expiry_time.split(' ')
          // userInfo.vip_expiry_time = time[0]
          this.$store.commit('getUserInfo',userInfo)
        }
      })
    },
    //获取消息通知
    getNoticeData(){
      let params = {
        page:this.pageIndex,
        limit:10,
      }
      noticeData(params).then(res=>{
        console.log('res',res)
        if(res.code == 1){
          // this.list  =res.data.list
          this.noticeInfo = res.data
          for(let item in this.noticeInfo.list){
            this.$set(this.noticeInfo.list[item],'show',false)
            if(this.noticeInfo.list[item].read_status == 0){
              this.$set(this.noticeInfo.list[item],'state',false)
            }else{
              this.$set(this.noticeInfo.list[item],'state',true)
            }
          }
          this.getUser()
        }else{
          this.$Message.error(`${res.msg}`)
        }
      })
    },
    //详情
    getNoticeInfo(item,index){
      if(item.show == true){
        let params = {
          id:item.id
        }
        noticeInfo(params).then(res=>{
          this.$set(this.noticeInfo.list[index],'state',true)
        })
      }

    },
    //分页
    handleCurrentChange(val){
      this.pageIndex = val
      this.getNoticeData()
    },
  },

}

</script>
<style >
p > img{
  width: 100% ;
  height: 100%;
}
</style>
<style lang='less' scoped>
@import '@assets/css/profile/account/messageNotice.less';
</style>