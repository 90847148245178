import request from "@utils/request";

//消息列表
export const noticeData = (params)=>{
    return request({
        url:'index/center/noticeList',
        method:'post',
        params,
    })
}
//消息详情
export const noticeInfo = (params)=>{
    return request({
        url:'index/center/noticeInfo',
        method:'post',
        params,
    })
}
